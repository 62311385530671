import { withSentry } from "@sentry/remix";
import "./utils/env.server";
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useNavigation } from
"@remix-run/react";
import { json } from "@remix-run/node";
import type { LinksFunction, LoaderFunctionArgs } from "@remix-run/node";
import { DirectionProvider, MantineProvider } from "@mantine/core";
import { cssBundleHref } from "@remix-run/css-bundle";
import globalStyle from "./styles/app.css";
import themeStyle from "./styles/theme.css";
import mantine from "@mantine/core/styles.css";
import "@mantine/carousel/styles.css?__remix_sideEffect__";
import mantineDatatable from "mantine-datatable/styles.css";
import mantineDropzone from "@mantine/dropzone/styles.css";
import mantineNotifications from "@mantine/notifications/styles.css";
import mantineDates from "@mantine/dates/styles.css";
import { Notifications } from "@mantine/notifications";
import { ModalsProvider } from "@mantine/modals";
import { rootAuthLoader } from "@clerk/remix/ssr.server";
import { ClerkErrorBoundary, ClerkProvider } from "@clerk/remix";
import { getTheme } from "./utils/theme";
import { i18next } from "./utils/i18n";

import type { LoadingBarRef } from "react-top-loading-bar";
import LoadingBar from "react-top-loading-bar";
import { cookieSession } from "./utils/cookie.server";
import { getAppLocalization } from "./models/cms.app-localization";
import { I18nextProvider, useSSR } from "react-i18next";
import { getLocale } from "./utils/i18n.server";
import { useEffect, useMemo, useRef } from "react";
import "dayjs/locale/ar";
import dayjs from "dayjs";

export const links: LinksFunction = () => [
...(cssBundleHref ? [{ rel: "stylesheet", href: cssBundleHref }] : []),
{ rel: "stylesheet", href: mantine },
{ rel: "stylesheet", href: mantineDatatable },
{ rel: "stylesheet", href: mantineDropzone },
{ rel: "stylesheet", href: mantineNotifications },
{ rel: "stylesheet", href: mantineDates },
{ rel: "stylesheet", href: globalStyle },
{ rel: "stylesheet", href: themeStyle },
{
  rel: "stylesheet",
  href: "https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&family=Tajawal:wght@500;600;700&display=swap"
}];


export async function loader(args: LoaderFunctionArgs) {
  const locale = await getLocale(args.request);
  const session = await cookieSession.getSession(
    args.request.headers.get("Cookie")
  );

  if (!session.has("lang")) {
    session.set("lang", locale as any);
  }

  const localization = await getAppLocalization("portal", locale);

  let clerkLocalization:
  Awaited<ReturnType<typeof getAppLocalization>> |
  undefined = undefined;

  if (locale === "ar") {
    clerkLocalization = await getAppLocalization("clerk", "ar");
  }

  if (
  !localization.success ||
  clerkLocalization && !clerkLocalization.success)
  {
    throw new Error("Localization not found");
  }

  i18next.addResourceBundle(
    locale,
    "translation",
    localization.data.attributes.content
  );
  i18next.changeLanguage(locale);

  return rootAuthLoader(
    args,
    async ({ request }) => {
      const { sessionId, userId, getToken } = request.auth;

      return json(
        {
          sessionId,
          userId,
          locale,
          localization: localization.data.attributes.content,
          clerkLocalization: clerkLocalization?.success ?
          clerkLocalization.data.attributes.content :
          undefined,
          getToken,
          ENV // TODO: return only public env vars
        },
        {
          headers: {
            "Set-Cookie": await cookieSession.commitSession(session)
          }
        }
      );
    },
    {
      loadUser: true,
      loadOrganization: true,
      loadSession: true
    }
  );
}

function Document() {
  const { ENV, locale, localization, clerkLocalization, clerkState } =
  useLoaderData<typeof loader>();

  const navigation = useNavigation();

  const theme = useMemo(() => getTheme(locale!), [locale]);
  const loadingBarRef = useRef<LoadingBarRef>(null);

  useEffect(() => {
    if (!loadingBarRef.current) return;
    if (navigation.state === "loading") loadingBarRef.current.continuousStart();
    if (navigation.state === "idle") loadingBarRef.current.complete();
  }, [navigation.state]);

  useSSR(
    {
      [locale!]: {
        translation: localization
      }
    },
    locale!
  );

  useEffect(() => {
    dayjs.locale(locale);
  }, []);

  return (
    <html lang={locale} dir={locale === "ar" ? "rtl" : "ltr"}>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        {/* {ENV.NODE_ENV === "development" && <DevTools />} */}
        <ClerkProvider
          clerkState={clerkState as any}
          localization={clerkLocalization}>

          <LoadingBar color={"#A130B2"} ref={loadingBarRef} height={4} />
          <MantineProvider theme={theme} defaultColorScheme="light">
            <I18nextProvider i18n={i18next}>
              <DirectionProvider detectDirection>
                <ModalsProvider>
                  <Notifications position="bottom-right" />
                  <Outlet />
                </ModalsProvider>
              </DirectionProvider>
            </I18nextProvider>
          </MantineProvider>
        </ClerkProvider>
        <ScrollRestoration />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(ENV)};
            window.locale = ${JSON.stringify(locale)};`
          }} />

        <Scripts />
        <LiveReload />
        {ENV.NODE_ENV === "production" &&
        <>
            <script
            dangerouslySetInnerHTML={{
              __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-NKW9WZN');`
            }}>
          </script>
          </>
        }
      </body>
    </html>);

}

export default withSentry(Document);

export const ErrorBoundary = ClerkErrorBoundary();